<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="8">
        <v-card height="350px" elevation="24">
          <div class="d-flex flex-no-wrap">
            <v-avatar
                class="ma-3 rounded d-none d-md-flex"
                size="320"
                tile
            >
              <v-img v-if="currentContest && !currentContest.length" src="https://tavernedesspores.fr/img/fantasy.jpg"></v-img>
              <v-img v-if="currentContest && currentContest.length" :src="'https://tavernedesspores.fr/img/' + currentContest[0].img"></v-img>
            </v-avatar>
            <div class="w-100">
              <v-card-title class="justify-center justify-sm-center justify-md-space-between">
                <v-chip class="mb-2 bkg-theme d-none d-sm-none d-md-flex" label>
                  <v-icon left>
                    mdi-trophy
                  </v-icon>
                  <span class="fs-20">Concours Actuel</span>
                </v-chip>
                <v-btn
                    outlined
                    rounded
                    text
                    class="bkg-orange white--text"
                    v-if="currentContest && currentContest.length"
                    :to="$store.state.isConnected ? '/post' : '/login'"
                >
                  Participer
                </v-btn>
              </v-card-title>
              <v-card-text v-if="currentContest && currentContest.length">
                <div class="text-center">
                  <p class="fs-25">- {{ currentContest[0].name }} -</p>
                </div>
                <div class="theme">
                  <p class="text-center color-orange d-none d-md-block">Thème :</p>
                  <p class="contest-theme text-center" v-html="'&ldquo;' + currentContest[0].theme + '&rdquo;'"></p>
                  <p class="text-center">Récompense : {{ currentContest[0].reward }}</p>
                  <p class="text-center">Début : {{ currentContest[0].start }}</p>
                  <p class="text-center">Fin : {{ currentContest[0].end }}</p>
                </div>
              </v-card-text>
              <v-card-text v-if="currentContest && !currentContest.length">
                <div class="theme">
                  <p class="contest-theme no-theme text-center">Aucun concours pour le moment. Revenez bientôt :)</p>
                </div>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card height="350px" elevation="24" v-show="!$store.state.isPremium">
          <div class="d-flex bordered flex-no-wrap h-100">
            <div class="w-100 h-100 premium">
              <v-card-title>
                <h2 class="w-100 text-center color-orange mb-2">
                  La Taverne premium
                </h2>
              </v-card-title>
              <v-card-text>
                <v-img src="../assets/beer.png" width="80" class="mx-auto d-none d-md-flex"></v-img>
                <p class="text-justify">Découvrez une nouvelle façon de soutenir la Taverne des Spores !</p>
                <p class="text-justify">
                  Pour seulement 2€/mois accédez à l'intégralité des Spores soumises précédemment ! (En plus, ca nous aide beaucoup...)
                </p>
                <p class="text-center">
                  <router-link to="/infos-premium">En savoir plus</router-link>
                </p>
              </v-card-text>
            </div>
          </div>
        </v-card>
        <v-card height="350px" elevation="24" v-show="$store.state.isPremium">
          <div class="d-flex bordered flex-no-wrap h-100">
            <div class="w-100 h-100 premium">
              <v-card-title>
                <h2 class="w-100 text-center color-orange mb-2">
                  La Taverne premium
                </h2>
              </v-card-title>
              <v-card-text>
                <v-img src="../assets/beer.png" width="80" class="mx-auto d-none d-md-flex"></v-img>
                <p class="text-center">Vous faites partie du club ;)</p>
                <p class="text-justify">
                  Vous avez donc accès à tous les avantages du premium !
                </p>
                <p class="text-center">
                  <v-btn class="bkg-orange"><span class="color-white" @click="managePremium()">Gérer mon abonnement</span></v-btn>
                </p>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="12" class="mt-5">
        <v-card elevation="24" class="text-center">
          <a style="font-size: 18px" target="_blank" class="color-orange pa-5" href="https://www.amazon.fr/hz/audible/mlp?actionCode=AMSTM1450129210001&tag=tds021-21">Marre de lire ? Passez à l'audio avec Audible !</a>
          <p style="font-size: 12px" class="font-italic">Profitez de 30 jours gratuits et soutenez la Taverne ;)</p>
        </v-card>
      </v-col>
    </v-row>
    <v-row id="row_news">
      <v-col sm="12" lg="9" class="mt-5">
        <h2 class="color-orange">Dernières News</h2>
      </v-col>
      <v-col sm="12" lg="3" class="mt-5 d-none d-lg-flex">
        <h2 class="text-center color-orange">Sur Twitter</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="9" class="bordered-right">
        <v-row>
          <v-col cols="12" v-for="news in lastNews" :key="news.id">
            <v-card
                class="mx-auto"
                outlined
                elevation="20"
            >
              <v-list-item three-line>
                <v-list-item-avatar
                    tile
                    size="100"
                    class="d-none d-md-flex"
                >
                  <v-img :src="'https://tavernedesspores.fr/img/' + news.image"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="overline">
                    {{ news.publication_date }}
                  </div>
                  <v-list-item-title class="headline mb-1 color-orange">
                    {{  news.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ news.short_text }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    rounded
                    text
                    class="bkg-orange white--text"
                    :to="'/news/' + news.id"
                >
                  LIRE
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="my-5 text-center">
              <v-btn color="secondary" @click="getNews(-3)" v-show="newsOffset>0" class="mr-5">
                <v-icon
                    large
                >mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn color="secondary" @click="getNews(3)">
                <v-icon
                    large
                >mdi-chevron-right</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex">
        <div style="max-height: 1250px; overflow-y : auto;width:100%">
          <TwitterFeed src="https://twitter.com/DesSpores?ref_src=twsrc%5Etfw"></TwitterFeed>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-5">
        <h2 class="text-center color-orange">Une Spore au hasard<br/><small class="d-none d-md-inline-block small-day">( chaque jour )</small></h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="24" v-if="!randomLoading" >
          <v-img :src="'https://tavernedesspores.fr/img/' + randomOne.contest_image" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                 height="150">
              <v-card-title >
                <span class="theme" v-html="'Concours ' + randomOne.contest_name +', sur le thème <strong>' + randomOne.theme + '</strong>'"></span>
              </v-card-title>
          </v-img>
          <v-card-text>
            <p class="px-5 text-center warning-spore">La Spore ci-dessous est affichée sans retouche ni contrôle. Ses propos ainsi que les fautes n'engagent que son auteur (Bienvenue dans la vie du jury) ;)</p>
            <p class="headline color-orange text-capitalize">
              {{ randomOne.title }}
            </p>
            <p>
              {{ randomOne.created_at }}
            </p>
            <p class="text-justify fs-25 spore-text" v-html="randomOne.text ">
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span class="fs-25 color-orange text-capitalize">{{ randomOne.name.indexOf('@')>0 ? 'Anonyme' : randomOne.name }}</span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="bkg-orange rounded mt-10">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2 class="color-white">Contact</h2>
        </v-col>
      </v-row>
      <v-row id="contact">
        <v-col cols="12">
          <div class="pa-5 color-white">
            <p class="color-white text-center">Vous avez trouvé un bug ? Vous souhaitez des informations ? Ou vous voulez tout simplement entrer en contact avec nous ? Remplissez le formulaire ci-dessous. Nous vous répondrons dans les plus brefs délais !</p>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="contactInfo.name"
                        :counter="80"
                        label="Votre nom"
                        required
                        outlined
                        solo
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="contactInfo.email"
                        :rules="emailRules"
                        :counter="80"
                        label="Votre email"
                        required
                        outlined
                        solo
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      md="4"
                  >
                    <v-text-field
                        v-model="contactInfo.subject"
                        label="Sujet"
                        required
                        outlined
                        solo
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-textarea
                        label="Votre message"
                        v-model="contactInfo.message"
                        outlined
                        solo
                        style="caret-color: red !important;"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn @click="sendContact()">
            Envoyer
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
    export default {
        name: 'Home',
        data: () => ({
          valid: false,
          lastNews: null,
          randomOne: null,
          randomLoading: true,
          currentContest: null,
          contactInfo: {
            'name': null,
            'email': null,
            'subject': null,
            'message': null
          },
          emailRules: [
            v => /.+@.+/.test(v) || 'E-mail doit être valide',
          ],
          newsOffset: 0
        }),
        mounted() {
          this.getNews(0);

          this.$http.get('/random').then(response => {
            this.randomOne = response.data.spore;
            this.randomLoading = false;
          }).catch(() => {
            this.randomLoading = false;
          });

          this.$http.get('/getContests').then(response => {
            this.currentContest = response.data;
          }).catch(() => {

          });
        },
        methods: {
          getNews: function(move) {
            this.newsOffset += move;
            if(this.newsOffset < 0) {
              this.newsOffset = 0;
            }
            this.$http.post('/getAllNews', {"offset": this.newsOffset}).then(response => {
              this.lastNews = response.data;
              if(this.newsOffset > 0) {
                location.hash = "#row_news";
              }
            }).catch(() => {

            });
          },
          sendContact: function() {
            if(this.valid) {
              this.$http.post('/contactMail', {form: this.contactInfo}).then(response => {
                if(response.data.success) {
                  this.$notify({
                    group: 'spore',
                    title: 'Super !',
                    text: 'Votre email a bien été envoyé. Merci !',
                    type: 'success'
                  });
                  this.contactInfo = {
                    "name":"",
                    "subject":"",
                    "message":"",
                    "email":""
                  };
                } else {
                  this.$notify({
                    group: 'spore',
                    title: 'Erreur...',
                    text: 'Un problème est survenue lors de l\'envoi de votre email. Merci de réessayer ultérieurement.',
                    type: 'error'
                  });
                }
              }).catch(() => {

              });
            } else {
              this.$notify({
                group: 'spore',
                title: 'Erreur...',
                text: 'Veuillez renseigner les champs obligatoires..',
                type: 'error'
              });
            }
          },
          managePremium: function() {
            this.$http.get('/stripe-portal').then(response => {
              if(response.data.url) {
                document.location.replace(response.data.url);
              }
            }).catch(() => {

            });
          }
        }
    }
</script>
<style scoped>
.contest-theme {
  font-size: 2em;
  font-style: italic;
}
.contest-theme.no-theme {
  line-height: 45px;
}
.theme {
  background: #efdba2;
  padding: 15px;
  border-radius: 5px;
}
.bordered {
  border: 2px solid #CC4425;
}
.bordered-right {
  border-right: 1px solid #dadada;
}
.warning-spore {
  color: #9e9e9e;
  font-style: italic;
  font-size: 16px;
}
.spore-text {
  line-height: 40px;
  color: #666666;
}
.small-day {
  font-size: 0.45em;
  color: #3E4147 !important;
  font-weight: lighter;
}
</style>
